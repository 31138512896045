

















































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";

import {
  DepartmentDto,
  UserDto,
  UserDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ResetPassword from "@/views/ou/user/resetPassword.vue";
import DepartmentSelect from "@/components/DepartmentSelect/index.vue";
import UserListDialog from "@/views/ou/user/userListDialog.vue";

interface UserQueryForm {
  keyword: string;
  departmentId: string;
  departments: string;
}

@Component({
  name: "roleUsers",
  components: {
    UserListDialog,
    DepartmentSelect,
    PagedTableView,
    AbSelect,
    ImportExcel,
    ResetPassword,
  },
})
export default class FoundationUsers extends Vue {
  tableItems: UserDtoPagedResultDto[] = [];
  roleId = 0;

  queryForm: UserQueryForm = {
    keyword: "",
    departmentId: "",
    departments: "",
  };

  userId = 0;

  departmentLoading = false;
  allDepartments = "";

  // 获取表数据
  fetchData(params: any) {
    params.mode = "foundation";
    params.departments = this.allDepartments;
    params.roleId = this.roleId;
    return api.user.getAll(params);
  }

  async created() {
    this.roleId = parseInt((this as any).$route.params.id);
  }

  get hasSelectDepartment(): boolean {
    return !!this.queryForm.departmentId;
  }

  handleDepartmentLoadCompleted(departments: DepartmentDto[]) {
    this.allDepartments = departments
      .map((department) => department.id!)
      .join(",");
    this.queryForm.departments = this.allDepartments;
    this.departmentLoading = false;
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "usersCreate",
      query: {
        departmentId: this.queryForm.departmentId,
        mode: "foundation",
      },
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "reset-password": {
        this.handleResetPassword(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
    }
  }

  // 编辑
  handleEdit(index: number, row: UserDto) {
    this.$router.push({
      name: "usersEdit",
      params: { id: row.id!.toString() },
      query: {
        mode: "foundation",
      },
    });
  }

  // 查看详情
  jumpDetail(index: number, row: UserDto) {
    this.$router.push({
      name: "usersDetail",
      params: { id: row.id!.toString() },
      query: {
        mode: "foundation",
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: UserDto) {
    (this as any).$confirm("你确定删除吗?", "提示").then(async () => {
      await api.user.delete({ id: row.id }).then((res) => {
        (this as any).$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  // 重置密码
  handleResetPassword(index: number, row: UserDto) {
    this.userId = row.id!;
    (this.$refs.resetPasswordForm as any).userId = this.userId;
    (this.$refs.resetPasswordForm as any).show = true;
  }

  handleDepartmentSelect(department: DepartmentDto) {
    this.queryForm.departmentId = department.id!.toString();
    this.queryForm.departments = "";
    this.refreshUserData();
  }

  handleClearDepartmentSelect() {
    this.queryForm.departmentId = "";
    this.queryForm.departments = this.allDepartments;
    this.refreshUserData();
  }

  showUserList(){
    (this.$refs.userListDialog as any).show = true;
  }

  refreshUserData() {
    (this.$refs.userTableList as any).refreshData();
  }
}
