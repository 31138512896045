

































import {
  Component,
  Vue,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {UserDto, UserRoleDto} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";

@Component({
  name: "userListDialog",
  components: {ElOptionSpan},
})
export default class ResetPassword extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: UserRoleDto = {
    userIds: [],
    roleNames: [],
  };

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      await this.getUserList();
    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  show = false;
  form: UserRoleDto = {...this.defaultData};
  userList: UserDto[] = [];
  loading = false;
  roleId = 0;
  disabled = false;

  cancel() {
    this.show = false;
    this.form = {...this.defaultData};
  }

  async getUserList() {
    this.loading = true;
    await api.user.getAll({maxResultCount: 65535, mode: "foundation"}).then((res) => {
      this.userList = res.items!;
      this.loading = false;
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.disabled = true;
        this.form.roleNames = [];
        this.roleId = parseInt((this as any).$route.params.id);
        console.log(this.roleId)
        await api.role.get({id: this.roleId}).then((res) => {
          if (res.name) {
            this.form.roleNames?.push(res.name)
            api.role.updateUserRole({body: this.form}).then((res) => {
              this.$message.success("添加成功");
              this.disabled = false;
              this.cancel()
            })
          }
        })
      }
    });
  }

  ruleRule = {
    userIds: [
      {
        required: true,
        message: "请选择用户",
        trigger: "blur",
      },
    ]
  };
}
