



















import { Component, Vue } from "vue-property-decorator";
import { DepartmentDto } from "@/api/appService";
import api from "@/api";
import { createTableTree } from "@/utils/tree";

@Component({ name: "DepartmentSelect" })
export default class DepartmentSelect extends Vue {
  tableItems: DepartmentDto[] = [];

  loading = true;

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    await api.department.getAll({ maxResultCount: 65535 }).then((res) => {
      const tree = createTableTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children"
      );
      this.tableItems = tree;
      this.loading = false;

      this.$emit("load-completed", res.items!);
    });
  }

  handleRowClick(row: DepartmentDto, column: any, event: any) {
    this.$emit("department-select", row);
  }
}
