












import { Component, Model, Vue, Watch } from "vue-property-decorator";
import { createNgTree, INgNode } from "@/utils/tree";
import { DepartmentDto, SelectListItem } from "@/api/appService";
import api from "@/api/index";

@Component({ name: "DepartmentDropdownSelect" })
export default class DepartmentDropdownSelect extends Vue {
  @Model("change", { required: true, default: undefined })
  readonly departmentValue?: number;

  private internalValue = "";
  private selectList: SelectListItem[] = [];

  created() {
    this.internalValue = this.departmentValue?.toString() ?? "";
    this.fetchData();
  }

  fetchData() {
    api.department
      .getAll({
        maxResultCount: 65535,
        skipCount: 0,
        sorting: "displayOrder asc",
      })
      .then((res) => {
        this.buildSelectList(undefined, res.items!, 0);
      });
  }

  buildSelectList(
    parentId: number | undefined,
    allDepartments: DepartmentDto[],
    level: number
  ) {
    const children = allDepartments.filter(
      (s) =>
        s.parentId === parentId ||
        (parentId === undefined && s.parentId === null)
    );
    if (children && children.length) {
      let whiteSpace = "";
      for (let i = 0; i < level; i++) {
        whiteSpace += "—";
      }

      children.forEach((item) => {
        let selectListItem: SelectListItem = {
          text: whiteSpace + item.displayName,
          value: item.id!.toString(),
        };
        this.selectList.push(selectListItem);

        this.buildSelectList(item.id, allDepartments, level + 1);
      });
    }
  }

  @Watch("departmentValue")
  handleDepartmentValueChange(newValue?: number) {
    if (newValue) {
      this.internalValue = newValue.toString();
    } else {
      this.internalValue = "";
    }
  }

  @Watch("internalValue")
  handleInternalValueChange(newValue: string) {
    this.$emit("change", newValue);
  }
}
