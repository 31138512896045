









import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  name: "ElOptionSpan",
})
export default class ElOptionSpan extends Vue {
  @Prop({required: true})
  name: any;
  @Prop({ required: true, default: "" })
  value!: string;
}
